import { gql, useQuery } from "@apollo/client";
import format from "date-fns/format";
import { useRouter } from "next/router";
import { useState } from "react";
import { Location, LocationTranslation } from "../../../graphql/operations";
import { addDuration, formatDate } from "../../../utils/calendarUtils";
import Button from "../../Atoms/Button";
import DropDown from "../../Atoms/DropDown";
import Loader from "../../Atoms/Loader";
import { useSearchContext } from "../SearchBar/SearchProvider";
import styles from "./TodayTomorrowSearch.module.scss";

export default function TodayTomorrowSearch() {
  const searchContext = useSearchContext();
  const { loading, error, data } =
    useQuery<GetLocationsResponse>(GET_LOCATIONS);

  const today = new Date();
  const tomorrow = addDuration(today, { days: 1 });
  const dayAfter = addDuration(today, { days: 2 });

  const [selectedCity, setSelectedCity] = useState<string | undefined>(
    undefined
  );
  const [selectedDay, setSelectedDay] = useState<Date>(today);
  const [selectedGuestNum, setSelectedGuestNum] = useState<string>("1");
  const [searching, setSearching] = useState(false);
  const router = useRouter();

  if (loading) return <></>;
  if (error || !data || !data.locations) return <></>;

  const cities = data.locations.records.map((r) => ({
    label: r.translation.name,
    value: r.id,
  }));
  const guestsNumbers = Array.from({ length: 10 }, (_, i) => ({
    label: `${i + 1} Guest${i + 1 > 1 ? "s" : ""}`,
    value: (i + 1).toString(),
  }));

  const handleCitySelect = (city: string) => {
    setSelectedCity(city);
  };
  const handleGuestSelect = (guest: string) => {
    setSelectedGuestNum(guest);
  };

  const performSearch = async () => {
    setSearching(true);
    searchContext.setFrom(selectedDay);
    searchContext.setLocationId(selectedCity);
    searchContext.setNumGuests(Number(selectedGuestNum));
    const chosenDay = formatDate(selectedDay);

    await router.push({
      pathname: "/search",
      query: {
        locationId: selectedCity,
        groupSize: selectedGuestNum,
        from: chosenDay,
        until: chosenDay,
        ref: "last-minute-button",
      },
    });
  };

  return (
    <div className={styles.TodayTomorrowSearchContainer}>
      <h3>Find Available Tours within 3 days</h3>
      <DropDown
        options={cities}
        selected={selectedCity}
        onSelect={handleCitySelect}
        label="Choose your city"
      />
      <div className={styles.DaySelection}>
        <div
          style={
            format(today, "MM/dd") === format(selectedDay, "MM/dd")
              ? { backgroundColor: "#E91E63" }
              : {}
          }
          onClick={() => setSelectedDay(today)}
        >
          Today <br />
          {format(today, "dd MMM")}
        </div>
        <div
          style={
            format(tomorrow, "MM/dd") === format(selectedDay, "MM/dd")
              ? { backgroundColor: "#E91E63" }
              : {}
          }
          onClick={() => setSelectedDay(tomorrow)}
        >
          Tomorrow <br />
          {format(tomorrow, "dd MMM")}
        </div>
        <div
          style={
            format(dayAfter, "MM/dd") === format(selectedDay, "MM/dd")
              ? { backgroundColor: "#E91E63" }
              : {}
          }
          onClick={() => setSelectedDay(dayAfter)}
        >
          Day After <br />
          {format(dayAfter, "dd MMM")}
        </div>
      </div>
      <DropDown
        options={guestsNumbers}
        selected={selectedGuestNum}
        onSelect={handleGuestSelect}
        label={"Select Number of Guests"}
      />
      <Button
        disabled={!selectedCity}
        style={{ borderRadius: 5, marginTop: 15 }}
        onClick={performSearch}
      >
        {searching ? <Loader size={14} /> : "Search Tours"}
      </Button>
    </div>
  );
}

type GetLocationsResponse = {
  locations: {
    records: (Pick<Location, "id"> & {
      translation: Pick<LocationTranslation, "name">;
    })[];
  };
};

const GET_LOCATIONS = gql`
  query TodayTomorrowCities {
    locations(
      input: {
        hasProducts: true
        locationTypes: [PREFECTURE, CITY]
        order: { field: "NUM_PRODUCTS", direction: DESC }
      }
    ) {
      records {
        id
        translation(lang: en) {
          name
        }
      }
    }
  }
`;
