import { LazyMotion, domAnimation, m } from "framer-motion";
import Head from "next/head";
import Link from "next/link";
import { MdChevronRight as ChevRight } from "react-icons/md";
import { CloudinaryImage } from "../components/Atoms/Cloudinary";
import RecentlyViewed from "../components/Cards/RecentlyViewed";
import GuideCarousel from "../components/Templates/Listing/GuideCarousel";
import { locationIds } from "../core/enums";
import { addApolloState, initializeApollo } from "../graphql/apolloClient";
import { Iso639 } from "../graphql/operations";
import styles from "../styles/Home.module.scss";

import { gql } from "@apollo/client";
import { useSession } from "../components/Atoms/AppSession";
import BannerVideo from "../components/Cards/BannerVideo/HomeVideo";
import CityNavigation from "../components/Cards/CityNavigation";
import CoreValues from "../components/Cards/CoreValues";
import FeaturedBanners from "../components/Cards/FeaturedBanners";
import LogoCarousel from "../components/Cards/LogoCarousel";
import CityTourCard from "../components/Cards/ProductCard";
import TodayTomorrowSearch from "../components/Cards/TodayTomorrowSearch";
import { addDuration, formatDate } from "../utils/calendarUtils";

const AWS_S3_IMAGE_BUCKET =
  "https://magical-trip.s3.ap-northeast-1.amazonaws.com";
export default function Home({
  tokyoProducts,
  kyotoProducts,
  osakaProducts,
  hiroshimaProducts,
  guidesList,
}) {
  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  const { countryCode } = useSession();
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="theme-color" content="#de2c24" />
        <meta
          name="description"
          content="Japan Small Group Tours with Local Guides. Book top food tours, night tours, and walking tours in Tokyo, Osaka, Kyoto and Hiroshima."
        />
        <meta
          name="keywords"
          content="travel plan, itinerary, things to do, book, guide, trip, tours, experiences, vacation, reviews, japan tours"
        />
        <meta property="og:url" content="https://www.magical-trip.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="MagicalTrip: Japan's Best Local Tours by Local Guides"
        />
        <meta
          property="og:description"
          content="Japan Small Group Tours with Local Guides. Book top food tours, night tours, and walking tours in Tokyo, Osaka, Kyoto and Hiroshima."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dbm1qiew0/image/upload/f_auto,q_auto/v1672881664/recruit/featured/mt-logo.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@magicaltripcom" />
        <meta
          name="twitter:title"
          content="MagicalTrip: Japan's Best Local Tours by Local Guides"
        />
        <meta
          name="twitter:description"
          content="Japan Small Group Tours with Local Guides. Book top food tours, night tours, and walking tours in Tokyo, Osaka, Kyoto and Hiroshima."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dbm1qiew0/image/upload/f_auto,q_auto/v1672881664/recruit/featured/mt-logo.jpg"
        />
        <title>
          MagicalTrip: Japan&apos;s Best Local Tours by Local Guides
        </title>
      </Head>
      <div>
        <BannerVideo
          videoSrc={`https://res.cloudinary.com/dbm1qiew0/video/upload/f_auto,q_auto,h_300,dpr_2/v1724807983/s3bucket/ace-content/top-video`}
          coverPoster={
            "https://res.cloudinary.com/dbm1qiew0/image/upload/f_auto,q_auto,w_1024,h_460,c_fill/v1589163651/s3bucket/ace-content/DSC05116.jpg"
          }
        />
      </div>
      {countryCode === "Asia/Tokyo" && <TodayTomorrowSearch />}

      <div className="AppLayout" style={{ marginTop: 40 }}>
        <h2 style={{ marginBottom: 15 }}>Find Local Tours in Japan</h2>
        <CityNavigation />
      </div>

      <div className="AppLayout">
        <RecentlyViewed />
        <h2 className="UIT UITSLL UITW1 UITC0 HomeCityHeader">
          <span>Tours in Tokyo</span>
        </h2>
        <div className={styles.HomeCityProducts}>
          {tokyoProducts.data.products.records.map((product, index) => {
            return <CityTourCard {...product} key={index} />;
          })}
        </div>
        <div className={styles.ExploreAllBtn}>
          <Link href={`/tours-japan/${locationIds.TOKYO}`}>
            Explore All Tokyo Tours{" "}
            <ChevRight
              color="#ec1e79"
              size={18}
              style={{
                position: "relative",
                top: 5,
              }}
            />
          </Link>
        </div>

        <h2 className="UIT UITSLL UITW1 UITC0 HomeCityHeader">
          <span>Tours in Kyoto</span>
        </h2>
        <div className={styles.HomeCityProducts}>
          {kyotoProducts.data.products.records.map((product, index) => {
            return <CityTourCard {...product} key={index} />;
          })}
        </div>
        <div className={styles.ExploreAllBtn}>
          <Link href={`/tours-japan/${locationIds.KYOTO}`}>
            Explore All Kyoto Tours{" "}
            <ChevRight
              color="#ec1e79"
              size={18}
              style={{
                position: "relative",
                top: 5,
              }}
            />
          </Link>
        </div>
        <LazyMotion features={domAnimation}>
          <m.div
            variants={container}
            exit={{ opacity: 0 }}
            initial={"hidden"}
            animate={{ opacity: 1 }}
            whileInView="visible"
          >
            <m.div variants={item} style={{ marginTop: 65 }}>
              <CoreValues />
            </m.div>
          </m.div>
        </LazyMotion>
        <h2 className="UIT UITSLL UITW1 UITC0 HomeCityHeader">
          <span>Tours in Osaka</span>
        </h2>
        <div className={styles.HomeCityProducts}>
          {osakaProducts.data.products.records.map((product, index) => {
            return <CityTourCard {...product} key={index} />;
          })}
        </div>
        <div className={styles.ExploreAllBtn}>
          <Link href={`/tours-japan/${locationIds.OSAKA}`}>
            Explore All Osaka Tours{" "}
            <ChevRight
              color="#ec1e79"
              size={18}
              style={{
                position: "relative",
                top: 5,
              }}
            />
          </Link>
        </div>
        <h2 className="UIT UITSLL UITW1 UITC0 HomeCityHeader">
          <span>Tours in Hiroshima</span>
        </h2>
        <div className={styles.HomeCityProducts}>
          {hiroshimaProducts.data.products.records.map((product, index) => {
            return <CityTourCard {...product} key={index} />;
          })}
        </div>
        <div className={styles.ExploreAllBtn}>
          <Link href={`/tours-japan/${locationIds.HIROSHIMA}`}>
            Explore All Hiroshima Tours{" "}
            <ChevRight
              color="#ec1e79"
              size={18}
              style={{
                position: "relative",
                top: 5,
              }}
            />
          </Link>
        </div>
      </div>

      <FeaturedBanners showHome={true} />

      <div className="AppLayout">
        <h2
          className="UIT UITSLL UITW1 UITC0 HomeCityHeader"
          style={{ marginTop: 40 }}
        >
          Joined by Travelers Across the World
        </h2>
        <LazyMotion features={domAnimation}>
          <m.div
            variants={container}
            exit={{ opacity: 0 }}
            initial={"hidden"}
            animate={{ opacity: 1 }}
            whileInView="visible"
            className={styles.programInfluencers}
          >
            <m.a
              variants={item}
              href="https://youtu.be/a_uAOoqooy4"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                height: 197,
                position: "relative",
              }}
            >
              <CloudinaryImage
                transformations={["w_350", "h_197"]}
                src={`${AWS_S3_IMAGE_BUCKET}/ace-content/shibuya_simonandmartina_1280%C3%97720.jpg`}
              />
            </m.a>
            <m.a
              variants={item}
              href="https://youtu.be/6VxOcSYNwXk"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                height: 197,
                position: "relative",
              }}
            >
              <CloudinaryImage
                transformations={["w_350", "h_197"]}
                src={`${AWS_S3_IMAGE_BUCKET}/ace-content/onlyinjapan1280%C3%97720.jpg`}
              />
            </m.a>
            <m.a
              variants={item}
              href="https://www.youtube.com/watch?v=15PqG0V3Xss"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                height: 197,
                position: "relative",
              }}
            >
              <CloudinaryImage
                transformations={["w_350", "h_197"]}
                src={`${AWS_S3_IMAGE_BUCKET}/ace-content/tokyobarhop_intarnationallyme_1280%C3%97720.jpg`}
              />
            </m.a>
            <m.a
              variants={item}
              href="https://youtu.be/GMXqhEOsvRM"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                height: 197,
                position: "relative",
              }}
            >
              <CloudinaryImage
                transformations={["w_350", "h_197"]}
                src={`${AWS_S3_IMAGE_BUCKET}/guides/kimdao_kamakura.jpg`}
              />
            </m.a>
            <m.a
              variants={item}
              href="https://youtu.be/TE9WY6-_QHo"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                height: 197,
                position: "relative",
              }}
            >
              <CloudinaryImage
                transformations={["w_350", "h_197"]}
                src={`${AWS_S3_IMAGE_BUCKET}/guides/hiroshima-youtuber.jpg`}
              />
            </m.a>
            <m.a
              variants={item}
              href="https://youtu.be/GMXqhEOsvRM"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                height: 197,
                position: "relative",
              }}
            >
              <CloudinaryImage
                transformations={["w_350", "h_197"]}
                src={`${AWS_S3_IMAGE_BUCKET}/guides/kimdao_shibuyaBH.jpg`}
              />
            </m.a>
          </m.div>
        </LazyMotion>
        <GuideCarousel guides={guidesList.data.guides} />
        <LogoCarousel />
      </div>
    </>
  );
}

const revalidate = process.env.ENVIRONMENT == "production" ? 3600 : 60;
export const getStaticProps = async (context: {
  params: any;
  query: any;
  req: any;
}) => {
  const today = new Date();
  const tomorrow = addDuration(today, { days: 1 });
  const until = addDuration(tomorrow, { days: 1 });
  try {
    const apolloClient = initializeApollo();

    const tokyoProducts = await apolloClient.query({
      query: GET_PRODUCTS,
      variables: {
        offset: 0,
        limit: 6,
        locationId: locationIds.TOKYO,
        language: Iso639.En,
        currency: "USD",
        fromDate: formatDate(today),
        untilDate: formatDate(until),
      },
    });

    const kyotoProducts = await apolloClient.query({
      query: GET_PRODUCTS,
      variables: {
        offset: 0,
        limit: 6,
        locationId: locationIds.KYOTO,
        language: Iso639.En,
        currency: "USD",
        fromDate: formatDate(today),
        untilDate: formatDate(until),
      },
    });

    const osakaProducts = await apolloClient.query({
      query: GET_PRODUCTS,
      variables: {
        offset: 0,
        limit: 6,
        locationId: locationIds.OSAKA,
        language: Iso639.En,
        currency: "USD",
        fromDate: formatDate(today),
        untilDate: formatDate(until),
      },
    });

    const hiroshimaProducts = await apolloClient.query({
      query: GET_PRODUCTS,
      variables: {
        offset: 0,
        limit: 6,
        locationId: locationIds.HIROSHIMA,
        language: Iso639.En,
        currency: "USD",
        fromDate: formatDate(today),
        untilDate: formatDate(until),
      },
    });

    const guidesList = await apolloClient.query({
      query: GET_GUIDES,
    });

    if (
      !tokyoProducts.data.products ||
      !kyotoProducts.data.products ||
      !osakaProducts.data.products ||
      !hiroshimaProducts.data.products ||
      !guidesList.data.guides
    ) {
      return {
        notFound: true,
      };
    }

    return addApolloState(apolloClient, {
      props: {
        tokyoProducts,
        kyotoProducts,
        osakaProducts,
        hiroshimaProducts,
        guidesList,
      },
      revalidate,
    });
  } catch {
    return {
      props: {
        tokyoProducts: {
          data: {
            products: {
              records: [],
            },
          },
        },
        kyotoProducts: {
          data: {
            products: {
              records: [],
            },
          },
        },
        osakaProducts: {
          data: {
            products: {
              records: [],
            },
          },
        },
        hiroshimaProducts: {
          data: {
            products: {
              records: [],
            },
          },
        },
        guidesList: {
          data: {
            guides: [],
          },
        },
      },
      revalidate: 1,
    };
  }
};

const GET_GUIDES = gql`
  query GetGuides {
    guides(input: { orderField: "numTours", offset: 0, limit: 8 }) {
      id
      nickName
      profilePic
    }
  }
`;
const GET_PRODUCTS = gql`
  query PosterCardProductsQuery(
    $offset: Int
    $limit: Int
    $locationId: ID
    $language: Iso639!
    $currency: Currency!
    $ids: [ID!]
    $fromDate: Date!
    $untilDate: Date!
  ) {
    products(
      input: {
        offset: $offset
        limit: $limit
        locationId: $locationId
        ids: $ids
      }
    ) {
      totalRecords
      records {
        id
        availability(fromDate: $fromDate, untilDate: $untilDate) {
          date
          status
        }
        translation(lang: $language) {
          headline
          name
          metaDescription
        }
        mainImage
        duration
        poster
        location {
          id
          imageUrl

          translation(lang: $language) {
            name
            description
            metaTitle
            metaDescription
          }
        }

        tags {
          id
          translation(lang: $language) {
            name
          }
        }
        price(currency: $currency) {
          amount
          currency
        }
        reviews {
          avgStars
          totalRecords
        }
      }
    }
  }
`;
