import React, { useEffect, useRef, useState } from "react";
import styles from "./DropDown.module.scss";

type Option = {
  label: string;
  value: string;
};

type DropdownProps = {
  options: Option[];
  label?: string;
  selected?: string;
  onSelect: (value: string) => void;
};

const Dropdown: React.FC<DropdownProps> = ({
  options,
  label,
  selected,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (value: string) => {
    onSelect(value);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.DropDown} ref={dropdownRef}>
      <button
        className={styles.DropDownToggle}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {selected
          ? options.find((option) => option.value === selected)?.label
          : label}

        <span className={styles.DropDownArrow}>{isOpen ? "▲" : "▼"}</span>
      </button>
      {isOpen && (
        <ul className={styles.DropDownMenu}>
          {options.map((option) => (
            <li
              key={option.value}
              className={styles.DropDownItem}
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
